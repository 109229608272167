import React from 'react'
import { Link } from 'gatsby-plugin-intl'

const MyLink = ({title, url, ...rest}) => {
  return url.startsWith('/') ? (
    <Link to={url} {...rest}>
      {title}
    </Link>
  ) : (
    <a target="_blank" rel="noopener noreferrer" href={url} {...rest}>
      {title}
    </a>
  )

}

export default MyLink
