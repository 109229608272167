import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import { map } from 'ramda'
import React, { useState } from 'react'
import { MAIN_MENU } from '../../constants'
import LanguageSwitch from './language-switcher'
import Link from './link'

const Navbar = () => {
  const language = useIntl().locale
  const [navbarOpen, setNavbarOpen] = useState(false)
  const toggleNavState = () => setNavbarOpen(!navbarOpen)
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "gemer.png" }) {
          childImageSharp {
            fixed(width: 64, height: 64) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <nav className="navbar main-navigation is-uppercase" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <GatsbyLink className="navbar-item has-text-weight-semibold" to="/">
          <Img className="logo" fixed={data.file.childImageSharp.fixed} alt="logo" />
        </GatsbyLink>

        <span
          onClick={toggleNavState}
          role="button"
          className={navbarOpen ? 'navbar-burger burger is-active' : 'navbar-burger burger'}
          aria-label="menu"
          aria-expanded="false"
          data-target="mainMenu"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </span>
      </div>
      <div id="mainMenu" className={navbarOpen ? 'navbar-menu is-active' : 'navbar-menu'}>
        <div className="navbar-start">
          {map(item => (
            <Link key={item[language].url} url={item[language].url} title={item[language].label} className="navbar-item" />
          ))(MAIN_MENU)}
        </div>

        <div className="navbar-end">
          <LanguageSwitch />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
