import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Newsletter = () => {
  const t = useIntl().formatMessage
  const [state, setState] = useState({})
  const [error, setError] = useState('')
  const [notificationVisible, setNotificationVisible] = useState(false)
  const showNotification = () => {
    setNotificationVisible(true)
    setTimeout(() => setNotificationVisible(false), 3000)
  }
  const handleChange = ({ currentTarget: target }) => {
    setState({ ...state, [target.name]: target.value })
  }
  const onSubmit = event => {
    event.preventDefault()
    if (!state.email) return
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.currentTarget.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        showNotification()
        setState({ email: '' })
      })
      .catch(error => setError(error))
  }
  return (
    <div className="column is-flex is-flex-direction-column is-half is-align-items-center newsletter">
      <form
        name="newsletter"
        onSubmit={onSubmit}
        data-netlify="true"
        method="post"
        netlift-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="newsletter" />
        <p className="is-hidden">
          <input name="bot-field" onChange={handleChange} />
        </p>
        <p className="is-size-5 mb-2">{t({ id: 'newsletter.signup' })}</p>
        <div className="field is-grouped">
          <p className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={state.email}
            />
          </p>
          <p className="control">
            <button className={`button is-outlined ${!state.email ? 'is-disabled' : ''}`}>
              {t({ id: 'newsletter.button' })}
            </button>
          </p>
        </div>
      </form>
      <div
        className={`notification ${error ? 'is-danger' : ''}`}
        style={{
          transform: `scaleY(${notificationVisible ? 1 : 0})`,
          transition: 'transform 0.15s ease-in',
        }}
      >
        {error ? error : t({ id: 'newsletter.thankYou' })}
      </div>
    </div>
  )
}

export default Newsletter
