import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useIntl } from 'gatsby-plugin-intl'

import Navbar from './navbar'
import Footer from './footer'
import '../css/main.scss'

import favicon from './favicon.png'

const Layout = ({ subTitle, noHeader, children, ...rest }) => {
  const intl = useIntl()
  const t = intl.formatMessage
  let title = t({ id: 'name' })
  if (subTitle) title += ` | ${subTitle}`
  return (
    <>
      <Helmet
        htmlAttribute={{ lang: t({ id: 'lang' }) }}
        title={title}
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
        link={[
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: `${favicon}`,
          },
        ]}
      />
      {noHeader || (
        <header className="site-header">
          <section className="navigation">
            <Navbar />
          </section>
        </header>
      )}
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  isHomePage: false,
}

export default Layout
