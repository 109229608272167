import React from 'react'
import Link from './link'

import { graphql, useStaticQuery } from 'gatsby'
import { FOOTER } from '../../constants'
import { useIntl } from 'gatsby-plugin-intl'
import { map } from 'ramda'
import Newsletter from './newsletter'

const Footer = () => {
  const language = useIntl().locale
  const { file, socialMedia } = useStaticQuery(
    graphql`
      query FooterQuery {
        file(relativePath: { eq: "gemer.png" }) {
          childImageSharp {
            fixed(width: 160) {
            ...GatsbyImageSharpFixed
            }
          }
        }
        socialMedia: allSocialMediaJson {
          edges {
            node {
              name
              url
            }
          }
        }
      }
    `
  )
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column">
            <figure className="image">
              <img srcSet={file.childImageSharp.fixed.srcSet} alt="logo"/>
            </figure>
          </div>
          <div className="column is-flex is-flex-direction-column">
            {map(item => (
              <Link
                key={item[language].url}
                title={item[language].label}
                url={item[language].url}
                className="footer-item"
              />
            ))(FOOTER[0])}
          </div>
          <div className="column is-flex is-flex-direction-column">
            {map(item => (
              <Link
                key={item[language].url}
                title={item[language].label}
                url={item[language].url}
                className="footer-item"
              />
            ))(FOOTER[1])}
            {socialMedia.edges.map(edge => (
              <Link key={edge.node.url} url={edge.node.url} title={edge.node.name} className="footer-item" />
            ))}
          </div>
          <Newsletter />
        </div>
      </div>
    </footer>
  )
}

export default Footer
