import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'

const LanguageSwitch = () => (
  <div className="navbar-item">
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map((language, index) => (
          <div key={language}>
            {index !== 0 && <span>/</span>}
            <span
              onClick={() => changeLocale(language)}
              aria-hidden="true"
              className={`language is-clickable ${
                currentLocale === language
                  ? `has-text-weight-bold`
                  : `has-text-weight-normal`
              }`}
            >
              {language}
            </span>
          </div>
        ))
      }
    </IntlContextConsumer>
  </div>
)

export default LanguageSwitch
