const LANGUAGES = ['en', 'sk']

const CATEGORIES = {
  Collaborators: {
    en: {
      label: 'Collaborators',
      url: '/collaborators',
    },
    sk: {
      label: 'SKCollaborators',
      url: '/skcollaborators',
    },
  },
  Research: {
    en: {
      label: 'Research',
      url: '/research',
    },
    sk: {
      label: 'SKResearch',
      url: '/skresearch',
    },
  },
  Program: {
    en: {
      label: 'Program',
      url: '/program',
    },
    sk: {
      label: 'SKProgram',
      url: '/skprogram',
    },
  },
}

const PAGES = {
  home: {
    en: {
      label: 'Home',
      url: '/',
    },
    sk: {
      label: 'SKHome',
      url: '/',
    },
  },
  archive: {
    en: {
      label: 'Archive',
      url: '/archive',
    },
    sk: {
      label: 'SKArchive',
      url: '/skarchive',
    },
  },
  resources: {
    en: {
      label: 'Resources',
      url: '/resources',
    },
    sk: {
      label: 'SKResources',
      url: '/skresources',
    },
  },
  contact: {
    en: {
      label: 'Contact us',
      url: '/contact-us',
    },
    sk: {
      label: 'SKContact Us',
      url: '/sk-contact-us',
    },
  },
}

const MAIN_MENU = [
  CATEGORIES.Program,
  CATEGORIES.Collaborators,
  CATEGORIES.Research,
  PAGES.archive,
  PAGES.resources,
  PAGES.contact,
]

const FOOTER = [
  [
    CATEGORIES.Program,
    CATEGORIES.Collaborators,
    CATEGORIES.Research,
    PAGES.archive,
  ],
  [PAGES.resources, PAGES.contact],
]

const isStaticPage = post => post.node.fileAbsolutePath.includes('.page.md')

const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')

const tagPath = (category, tag, lang) =>
  `${category[lang].url}/t/${toKebabCase(tag)}`

const createTagTranslator = tags => {
  return (tag, from, to) => {
    if (from === to) return tag
    const key = lang => `tags_${lang}`
    const index = tags[key(from)].indexOf(tag)
    return index !== -1 ? tags[key(to)][index] : undefined
  }
}

const otherLang = lang => {
  switch (lang) {
    case 'en':
      return 'sk'
    case 'sk':
      return 'en'
  }
}

module.exports = {
  CATEGORIES,
  PAGES,
  LANGUAGES,
  MAIN_MENU,
  FOOTER,
  tagPath,
  isStaticPage,
  createTagTranslator,
  otherLang,
}
